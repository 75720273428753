<template>
    <form style="width: 100%;">
        <transition-group name="list" tag="div">
        <div v-for="(node, index) in tree.children" :key="index" :class="$style.inputRow">
            <div :class="$style.stepCount">{{ index + 1 }}</div>
            <p :class="$style.rowText">We use</p>
            <TelescopicTextNode :node="node" :opened="true" :paragraphs="true" v-on="$listeners"/>
        </div>
        </transition-group>
    </form>
</template>

<script>
import TelescopicTextNode from '../TelescopicTextNode/TelescopicTextNode.vue'

export default {
    name: 'TelescopicText',
    components: {
        TelescopicTextNode 
    },
    data() {
        return {
        }
    },
    props: {
        tree: Object,
    },
    methods:{
        expand(item){
            item.expand=true
        }
    }
}
</script>

<style module src="./AssesmentList.css" />
<style>
.list-enter-active, .list-leave-active {
  transition: all 0.5s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
