<template>
    <div :class="$style.wrapper">
        <div :class="$style.row">
            <p :class="$style.shareSectionTitle">
                Share
            </p>
        </div>
        <div :class="$style.row">
            <p :class="$style.shareBody">
                Help colleagues and other teams to debiased their hiring
                process.
            </p>
            <div><CopyBar :inputText="shareLink" :class="$style.marmotCopyBarOverride" /></div>
            <div :class="$style.socialColumn">
                <div ref="linkedIn" :class="$style.linkedIn"></div>
                <div :class="$style.twitter">
                    <a
                        class="twitter-share-button"
                        :href="twitterLink"
                        data-size="large"
                        target="_blank"
                    >
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CopyBar } from "@applied/marmot"

export default {
    name: "PostToSocial",
    components: {
        CopyBar,
    },
    props: {
        state: String,
    },
    data() {
        return {
            socialScript: null,
        }
    },
    created() {
        this.socialScript = document.createElement("script")
        this.socialScript.setAttribute("data-url", "https://www.linkedin.com")
        this.socialScript.setAttribute("type", "IN/Share")
        this.socialScript.setAttribute("data-size", "large")
    },
    mounted() {
        this.$refs.linkedIn.appendChild(this.socialScript)

        this.$nextTick(() => {
            window.FB.XFBML.parse()
            window.IN.parse()
            window.twttr.ready(() => window.twttr.widgets.load())
        })
    },
    computed: {
        shareLink() {
            if (this.state)
                return `https://hiring-calculator.beapplied.com/#/?state=${this.state}`
            return `https://hiring-calculator.beapplied.com/`
        },
        twitterLink() {
            return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                this.shareLink
            )}`
        },
        facebookLink() {
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                this.shareLink
            )}&src=sdkpreparse`
        },
        linkedInLink() {
            return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                this.shareLink
            )}`
        },
    },
}
</script>

<style module src="./ShareSection.css" />
