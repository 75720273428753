<template>
    <div>
        <!-- <div :class="$style.topFooter">
            <div :class="[$style.footerInner, $style.topFooterInner]">
                <div :class="$style.leftColumn">
                    <p :class="$style.lightGrey">
                        Applied logo?
                    </p>
                </div>
                <div :class="$style.rightColumn">
                    <div :class="$style.linkColumn">
                        <p :class="$style.columnHeader">Products</p>
                        <a href="#" :class="$style.textLink">Platform</a>
                        <a href="#" :class="$style.textLink"
                            >The Applied Sift</a
                        >
                        <a href="#" :class="$style.textLink"
                            >Platform pricing</a
                        >
                        <a href="#" :class="$style.textLink"
                            >Job Description Tool</a
                        >
                        <a href="#" :class="$style.textLink">Job Board</a>
                    </div>
                    <div :class="$style.linkColumn">
                        <p :class="$style.columnHeader">Company</p>
                        <a href="#" :class="$style.textLink">About</a>
                        <a href="#" :class="$style.textLink">Careers</a>
                        <a href="#" :class="$style.textLink">Contact Us</a>
                    </div>
                    <div :class="$style.linkColumn">
                        <p :class="$style.columnHeader">Learn</p>
                        <a href="#" :class="$style.textLink">Resources</a>
                        <a href="#" :class="$style.textLink">Customers</a>
                        <a href="#" :class="$style.textLink">Blog</a>
                        <a href="#" :class="$style.textLink">Help Center</a>
                    </div>
                    <div :class="$style.linkColumn">
                        <p :class="$style.columnHeader">Community</p>
                        <a href="#" :class="$style.textLink"
                            ><FontAwesomeIcon
                                :class="$style.faIcon"
                                :icon="trainingIcon"
                            />Free Training</a
                        >
                        <a href="#" :class="$style.textLink"
                            ><FontAwesomeIcon
                                :class="$style.faIcon"
                                :icon="twitterIcon"
                            />Twitter</a
                        >
                        <a href="#" :class="$style.textLink"
                            ><FontAwesomeIcon
                                :class="$style.faIcon"
                                :icon="linkedInIcon"
                            />LinkedIn</a
                        >
                        <a href="#" :class="$style.textLink"
                            ><FontAwesomeIcon
                                :class="$style.faIcon"
                                :icon="facebookIcon"
                            />Facebook</a
                        >
                    </div>
                </div>
            </div>
        </div> -->
        <div :class="$style.bottomFooter">
            <div :class="[$style.footerInner, $style.bottomFooterInner]">
                <div :class="$style.leftColumn">
                    <p :class="$style.lightGrey">
                        Coyright © 2021 Be Applied Ltd.
                    </p>
                </div>
                <div :class="$style.rightColumn">
                    <a href="#" :class="$style.textLink">About</a>
                    <a href="#" :class="$style.textLink">Privacy</a>
                    <a href="#" :class="$style.textLink">Web Policy</a>
                    <a href="#" :class="$style.textLink">Terms of Use</a>
                    <a href="#" :class="$style.textLink">Security</a>
                    <a href="#" :class="$style.textLink">JDAT T&Cs</a>
                    <a href="#" :class="$style.textLink">ManageCookies</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faMicrophoneAlt } from "@fortawesome/free-solid-svg-icons"
import {
    faTwitter,
    faLinkedin,
    faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

export default {
    name: "Footer",
    components: {
        FontAwesomeIcon,
    },
    computed: {
        trainingIcon() {
            return faMicrophoneAlt
        },
        twitterIcon() {
            return faTwitter
        },
        linkedInIcon() {
            return faLinkedin
        },
        facebookIcon() {
            return faFacebookF
        },
    },
}
</script>

<style module src="./Footer.css" />
