import { render, staticRenderFns } from "./SummaryStats.vue?vue&type=template&id=4717f8b2&"
import script from "./SummaryStats.vue?vue&type=script&lang=js&"
export * from "./SummaryStats.vue?vue&type=script&lang=js&"
import style0 from "./SummaryStats.css?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports