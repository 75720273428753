<template>
    <div :class="$style.tripleColumn">
        <div>
            <p :class="$style.colHeader">Diversity</p>
            <div
                v-if="diversitySummary === 'good'"
                :class="[$style.iconWrapper, $style.wrapperGood]"
            >
                <FontAwesomeIcon :class="$style.faIcon" :icon="checkIcon" />
            </div>
            <div v-else :class="[$style.iconWrapper, $style.wrapperBad]">
                <FontAwesomeIcon :class="$style.faIcon" :icon="timesIcon" />
            </div>
            <p v-if="diversitySummary === 'good'">
                Good for diversity
            </p>
            <p v-else>
                Needs attention
            </p>
        </div>
        <div :class="$style.centerCol">
            <p :class="$style.colHeader">Efficiency</p>
            <div
                v-if="efficiencySummary === 'good'"
                :class="[$style.iconWrapper, $style.wrapperGood]"
            >
                <FontAwesomeIcon :class="$style.faIcon" :icon="checkIcon" />
            </div>
            <div v-else :class="[$style.iconWrapper, $style.wrapperBad]">
                <FontAwesomeIcon :class="$style.faIcon" :icon="timesIcon" />
            </div>
            <p v-if="efficiencySummary === 'good'">
                Great efficiency
            </p>
            <p v-else>Needs attention</p>
        </div>
        <div>
            <p :class="$style.colHeader">Predictivity</p>
            <div
                v-if="diversitySummary === 'good'"
                :class="[$style.iconWrapper, $style.wrapperGood]"
            >
                <FontAwesomeIcon :class="$style.faIcon" :icon="checkIcon" />
            </div>
            <div v-else :class="[$style.iconWrapper, $style.wrapperBad]">
                <FontAwesomeIcon :class="$style.faIcon" :icon="timesIcon" />
            </div>
            <p v-if="diversitySummary === 'good'">
                Highly predictive
            </p>
            <p v-else>Needs attention</p>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons"

export default {
    name: "SummaryStats",
    props: {
        efficiencySummary: String,
        diversitySummary: String,
    },
    components: {
        FontAwesomeIcon,
    },
    computed: {
        checkIcon() {
            return faCheck
        },
        timesIcon() {
            return faTimes
        },
    },
}
</script>

<style module src="./SummaryStats.css" />
