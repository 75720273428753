

exports.traverse = (objNode, fn) => {
    for (var prop in objNode) {
        fn.apply(this,[prop,objNode[prop],objNode]);  
        if (objNode[prop] !== null && typeof(objNode[prop])=="object") {
            const continueTraversing = exports.traverse(objNode[prop], fn);
            if ( continueTraversing === false ) break;
        }
    }
}

exports.findNodeByProp = (queryProp, value, rootNode) => {
    let matchingNode
    if (rootNode && rootNode[queryProp] === value) {
        return rootNode
    }
    exports.traverse(rootNode, (prop, leafNode) => {
        if (leafNode && leafNode[queryProp] === value){
            matchingNode = leafNode
            return false
        }
    })
    return matchingNode
}

exports.deleteNodeById = (rootNode, id) => {
    exports.traverse(rootNode, (prop, leafNode, parent) => {
        if (leafNode && leafNode.id === id){
            if (Array.isArray(parent)) {
                parent = parent.splice(prop,1)
            }
            else if ( typeof parent === "object" ) delete parent[prop]
            else { console.error(new Error('cool cool cool')) }
        }
    })
}

exports.findNodeById = (rootNode, id) => {
    return exports.findNodeByProp('id', id, rootNode)
}

exports.parse = (text) => {
    return {text}
}