import { jsPDF } from "jspdf";
import "jspdf/dist/polyfills.es.js";
import 'jspdf-autotable'
import { format as dateFormat } from 'date-fns'
import {
    getTotalTimeSpent, 
    getAverageTopPercentileCandidates, 
    getTopCandidatesHired, 
    getAverageLowestPercentile
} from './assessment'



const toDataURL = async (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

const addPageParaphernalia = (doc, data, pageIndex, logoString) => {
    // doc.addImage(logoString, 'PNG', 10, 10, data.logo.width, data.logo.height );
    doc.setTextColor(90)
    doc.setFontSize(12);
    doc.text(180, 275, `Page ${pageIndex+1} of ${data.stages.length+6}`);
}

const subTitle = (doc, height, text, options) => {
    doc.setTextColor(options && options.color || 140)
    doc.setFontSize(options && options.size || 10)
    doc.text(15, height, text.toUpperCase())
}

const pageHeading = (doc, text) => {
    // doc.setTextColor(140)
    // doc.setTextColor('#083572');
    doc.setTextColor('#F1B435');
    doc.setFontSize(24)
    doc.text(15, 50, text)
}
const heading = (doc, height, text, width) => {
    doc.setTextColor(120)
    doc.setFontSize(20)
    doc.text(15, height, doc.splitTextToSize(text, width || 200));
}

const paragraph = (doc, height, text, options) => {
    doc.setTextColor(options && options.color || 90)
    doc.setFontSize(options && options.size || 12)
    doc.text(options && options.left || 15, height, doc.splitTextToSize(text, options && options.width || 180));
}

const formatTimeSpent = (minutes) => {
    if ( minutes > 2400 ) return `${(minutes/(60*8*5)).toFixed(1)} weeks`
    if ( minutes > 960 ) return `${(minutes/(60*8)).toFixed(1)} days`
    if ( minutes > 120 ) return `${Math.round(minutes/60)} hours`
    return `${minutes} minutes`
}

const buildCoverPage = (doc, coverString, logoString) => {
    
    const pageSize = doc.internal.pageSize
    doc.setFillColor('#083572');
    doc.rect(0, 0, pageSize.getWidth(), pageSize.getHeight(), "FD");
    //doc.addImage(coverString, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight() );
    doc.addImage(logoString, 'PNG', 20, 20, 60.8, 24 );

    paragraph(doc, 270, `Hiring Pipeline Report`, {size:36, width:140, color:'white'})
    subTitle(doc, 282, `Based on data provided ${dateFormat(new Date(),"do MMM yyyy")}`, {color:'white'})
    
    // pageHeading(doc, `Hiring pipeline analysis`)
    // subTitle(doc, 58, `Generated for ${data.company.name} on the ${dateFormat(new Date(),"do MMM yyyy")}`)
    // paragraph(doc, 70, `This report contains simulated data based on scientific research into the assessment types specified, as well as listing resources for further research or training. It's presented free of charge and without liability.`)

    // paragraph(doc, 100, `High level stats`)
    // paragraph(doc, 120, `Key recommendations`)
}

const buildAboutPage = (doc, data) => {
    doc.addPage()
    pageHeading(doc, `About Applied`)

    paragraph(doc, 70,    
`Applied was set up in 2015 with the support of some of the world's most prominent behavioural scientists to use data to make hiring fair.

The world of hiring has long been focused on CVs, connections, and unstructured interviews. But these cruxes are fundamentally biased—letting skilled, passionate, diverse people fall through the cracks. 

Applied breaks the conventional wisdom of hiring and pushes back against common practices. Not to disrupt for disruption’s sake, but because we fundamentally believe in a better way forward. 

When you change your conventions, you alter the trajectory of your candidates, people, and the entire organization.`,
    {size:16, width:140})

    doc.setTextColor('#2167C4')
    doc.textWithLink('https://www.beapplied.com', 15, 225, { url: 'https://www.beapplied.com' });
    doc.textWithLink('@beapplied', 15, 235, { url: 'https://twitter.com/beapplied' });
}

const hiredCandidates = (pipeline) => {
    const stages = pipeline
    return parseInt(stages[stages.length-1].limit)
}

const goodHires = (results) => {
    const targetPercentile = 90
    return getAverageTopPercentileCandidates(results, targetPercentile)
}


const buildSummaryPage = (doc, data) => {

    const hired = hiredCandidates(data.pipeline)
    const perfectHires = getTopCandidatesHired(data.results)
    const lowestPercentile = getAverageLowestPercentile(data.results)

    doc.addPage()
    pageHeading(doc, `Summary`)
    paragraph(doc, 65, `This report contains simulated data based on scientific research into the assessment types specified, as well as listing resources for further research or training.`)

    paragraph(doc, 90, `Headlines after 100 simulations`, { size:20, color:140 })

    doc.setFillColor('#F1F4F7');
    doc.rect(15, 100, 170, 40, "F");
    paragraph(doc, 110, `- ${goodHires(data.results).toFixed(1)} of your ${hired} hires were in the best 10% by ability`, { size:14, color:100, left:20 })
    paragraph(doc, 118, `- you lost ${(hired-perfectHires).toFixed(1)} of the best ${hired} candidates`, { size:14, color:100, left:20 })
    paragraph(doc, 126, `- your weakest hire was only better than ${lowestPercentile.toFixed(0)}% of candidates`, { size:14, color:100, left:20 })
    paragraph(doc, 134, `- you spent an estimated ${getTotalTimeSpent(data.results)} working days per candidate hired`, { size:14, color:100, left:20 })
    

    doc.autoTable({
        startY: 155,
        margin: { right:20 },
        columnStyles: {
            '4':{
                minCellWidth: 25
            }
        },
        bodyStyles: {
            cellPadding: {top: 2, right: 2, bottom: 3, left: 2},
        },
        // headStyles: { fillColor:'#2167C4' },
        // theme: 'striped',
        // head: [[{ content:'Average over 100 simulations', colSpan:2}]], //, styles: StyleDef
        head: [['', 'Pipeline stage', 'Predictivity (r)', 'Candidates', 'Time spent', 'Suggestion']], //, styles: StyleDef
        body: data.stages.map((stage, i) => {
            const assessmentType = data.assessmentTypes[stage.type]
            return [
                i+1,
                assessmentType.name,
                assessmentType.correlation,
                stage.candidates.length,
                formatTimeSpent(stage.candidates.length * assessmentType.minutesPerCandidate),
                assessmentType.nudge || '',
            ]
        })
    })
}



const buildStagePage = (doc, data, i, canvas) => {
    const stage = data.stages[i]
    const assessmentType = data.assessmentTypes[stage.type]
    doc.addPage()
    
    pageHeading(doc, `Stage ${i+1}: ${assessmentType.name}`)    
    paragraph(doc, 65, assessmentType.description)
    
    doc.autoTable({
        margin: { top: 90, right:125 },
        // theme: 'striped',
        bodyStyles: {
            cellPadding: 2,
        },
        head: [[{ content:'Average over 100 simulations', colSpan:2}]], //, styles: StyleDef
        body: [
            ['Predictivity (r)', assessmentType.correlation ],
            ['Est. mins/candidate', assessmentType.minutesPerCandidate ],
            ['Candidates processed', stage.candidates.length ],
            ['Total time spent', formatTimeSpent(stage.candidates.length * assessmentType.minutesPerCandidate) ],
            ['Candidates selected', stage.remainingCandidates.length ],
            ['Correctly selected', stage.avgTruePositives ],
            ['Incorrectly selected', stage.avgFalsePositives ],
            ['Incorrectly rejected', stage.avgFalseNegatives ],
            ['Correctly rejected', stage.avgTrueNegatives ],
        ]
    })
    
    // paragraph(doc, 100, `[include data-driven info here about how many good candidates they lost, etc]`,60)
    try {
        const canvasImg = canvas.toDataURL("image/png");
        doc.addImage(canvasImg, 'PNG', 90, 90, 100, 100);
    } catch(err){
        console.error(err)
    }

    // if ( assessmentType.nudge ){
    //     doc.setFillColor('#FCEED1');
    //     doc.rect(15, 100, 100, 20, "FD");

    // }

    heading(doc, 210, `Recommendations`)
    paragraph(doc, 220, assessmentType.recommendations)
}

const buildFurtherLearning = (doc, data) => {
    doc.addPage()
    pageHeading(doc, `Resources you can't get anywhere else`)
    paragraph(doc, 65, `Applied offers a growing range of free resources to help teams improve their hiring practices.`)

    heading(doc, 85, `Free training`)
    paragraph(doc, 95, `Get insights from one of our many webinars.`)
    doc.setTextColor('#2167C4')
    doc.textWithLink('https://www.beapplied.com/events', 15, 100, { url: 'https://www.beapplied.com/events?utm_source=HealthcheckReport&utm_medium=Webinars&utm_campaign=HealthcheckReport&utm_content=Webinars' });
    
    paragraph(doc, 110, `Join one of our popular (and free) upcoming training days.`)
    doc.setTextColor('#2167C4')
    doc.textWithLink('https://www.beapplied.com/training-how-to-debias-your-recruitment', 15, 115, { url: 'https://www.beapplied.com/training-how-to-debias-your-recruitment?utm_source=HealthcheckReport&utm_medium=Trainingdays&utm_campaign=HealthcheckReport&utm_content=Trainingdayslink' });
    
    heading(doc, 135, `Be part of something new`)
    paragraph(doc, 145, `Come join the conversation on our LinkedIn community.`)
    doc.setTextColor('#2167C4')
    doc.textWithLink('https://www.linkedin.com/groups/8947119/', 15, 150, { url: 'https://www.linkedin.com/groups/8947119/' });
    
    paragraph(doc, 160, `Keep up to date with our newsletter.`)
    doc.setTextColor('#2167C4')
    doc.textWithLink('https://www.beapplied.com/newsletter-signup', 15, 165, { url: 'https://www.beapplied.com/newsletter-signup?utm_source=HealthcheckReport&utm_medium=Newsletter&utm_campaign=HealthcheckReport&utm_content=Newsletter' });
    
    heading(doc, 185, `Make a real change`)
    paragraph(doc, 195, `Check out the Applied platform to discover a better way forward.`)
    doc.setTextColor('#2167C4')
    doc.textWithLink('https://www.beapplied.com/features', 15, 200, { url: 'https://www.beapplied.com/features?utm_source=HealthcheckReport&utm_medium=Platform&utm_campaign=HealthcheckReport&utm_content=Platform' });
    
}

const buildAppendixA = (doc, data) => {
    doc.addPage()
    pageHeading(doc, `Appendix A - Assessment data`)

    paragraph(doc, 65, `Below are listed the numbers we've used to power our hiring pipeline calculator.`)

    const assessmentTypes = Object.keys(data.assessmentTypes).map(key => data.assessmentTypes[key] )

    doc.autoTable({
        margin: { top: 75 },
        bodyStyles: {
            cellPadding: 2,
        },
        theme: 'striped',
        head: [['Assessment', 'Predictivity (r)', 'Source', 'Est. mins/candidate' ]],
        body: assessmentTypes
            .sort((a,b) => { return b.correlation - a.correlation })
            .map(type => { return [type.name, type.correlation.toFixed(2), type.source, type.minutesPerCandidate ] })
    })
 
    paragraph(
        doc, 
        doc.lastAutoTable.finalY + 10, 
        `Schmidt, F. and Hunter J.e. (1998) The Validity and Utility of Selection Methods in Personnel Psychology: Practical and Theoretical Implications of 85 Years of Research Findings, Psychological Bulletin,  124(2), 262-274. Schmidt, F. and Hunter J.e. (2016) The Validity and Utility of Selection Methods in Personnel Psychology: Practical and Theoretical Implications of 100 Years of Research Findings. Working paper.`,
        { size: 8 },
    )
}

const buildAppendixB = (doc, data) => {
    doc.addPage()
    pageHeading(doc, `Appendix B - General hiring principles`)

    paragraph(doc, 65,
`Below are some of the principles that we at Applied use, both in our own hiring and in the design of our platform.`)

    doc.autoTable({
        margin: { top: 80, right:20 },
        bodyStyles: {
            cellPadding: {top: 3, right: 2, bottom: 5, left: 2},
        },
        columnStyles:{
            '0': {
                fontStyle:'bold'
            }
        },
        // theme: 'striped',
        head: [['Principle','Explanation']], //, styles: StyleDef
        body: [
            [
                `Bias is error`,
                `Every bit of bias that you allow in your hiring process reduces the chance of identifying the best performing candidate. Be relentless in identifying and eradicating sources of bias.`
            ],
            [
                `Design around bias`,
                `Bias is human nature, and can't be trained away in any broad or lasting sense. Instead it needs to be designed around at every stage of hiring.`
            ],
            [
                `Assess the actual job`,
                `Test candidates ability to perform actual day-to-day tasks from the role, as that gives a much stronger signal of later performance than abstract proxies like education history.`
            ],
            [
                `Wisdom of crowds`,
                `Use multiple independent opinions to get more accurate scoring. Everybody's opinion contains a degree of noise; averaging across multiple independent opinions lessens that noise.`,
            ],
            [
                `Don't move goalposts`,
                `It's easy to unconsciously shift requirements to favor preferred candidated. Does your new digital marketer -really- need to be able to speak Japanese or do you just like them?`,
            ],
            [
                `Avoid groupthink`,
                `Senior team members opinions often drown out the opinions of quieter or more junior team members, throwing away the value of having multiple opinions. Writing down scores before discussing the candidate will protect the value of independent judgement.`,
            ],
            [
                `Interviewers discover, not decide`,
                `When interviews are about discovery, interviewers are free to gather assessment data in a non-confrontationl way. This gives the interviewer one less thing to think about, and reduces the barrier between interviewer and candidate.`
            ],
            [
                `Minimise information`,
                `To get the clearest signal, keep each assessment stage independent of previous stages, eg. don't give interviewers CVs or tell them which candidate is strong or weak.`
            ],
            [
                `Get more data`,
                `Combining independent assessment stages can increase the accuracy; they become greater than the sum of their parts. If you can, be generous with who you let through to each stage, and then decide based on performance over multiple stages.`
            ],
        ]
    })
}

const buildAppendixC = (doc, data) => {
    doc.addPage()
    pageHeading(doc, `Appendix C - Notes on methodology`)

    paragraph(doc, 65,
`The research provides us with a correlation value (r) for each assessment type. This gives us a broad understanding of the relationship between actual ability and percieved ability. For each assessment type we then determined a signal to noise ratio that, when applied to randomly generated ability scores following a Normal distribution, produced the same correlation as the research. This gave us the ability to simulate random candidates travelling through an arbitrary combination of assessment stages.

Our model assumes that ability follows a normal distribution. We generate normally distributed 'true' ability scores using the Ziggurat algorithm. 

This model also assumes that assessment rounds are independent. It doesn't take into account situations where data from multiple rounds is used to make an assessment decision, mainly because that would make the user interface prohibitively complicated. As mentioned in the Appendix B, you can improve the performance of individual assessment stages by combining insights from prior stages.`)
}


const getImages = ( pageLogo, cb ) => {
    toDataURL(pageLogo, (pageLogoString) => {
        // toDataURL(require('@/assets/andy-just-because.png'), (coverString) => {
            toDataURL(require('@/assets/applied-logo-white.png'), (coverLogoString) => {
                cb(pageLogoString, undefined, coverLogoString)
            })
        // })
    })
}

export const generate = (data, stageCanvases, name, cb) => {

    getImages(data.logo.url, (pageLogoString, coverString, coverLogoString) => {
        // toDataURL(require('@/assets/andy-just-because.png'), (coverString) => {
            // Default export is a4 paper, portrait, using millimeters for units
        const doc = new jsPDF();
        doc.setLineHeightFactor(1.5)

    
        buildCoverPage(doc, coverString, coverLogoString)

        buildAboutPage(doc, data)
        addPageParaphernalia(doc, data, 0, pageLogoString)

        buildSummaryPage(doc, data)
        addPageParaphernalia(doc, data, 1, pageLogoString)

        data.stages.forEach((stage, i) => {
            buildStagePage(doc, data, i, stageCanvases[i])
            addPageParaphernalia(doc, data, i+2, pageLogoString)
        })

        buildFurtherLearning(doc, data)
        addPageParaphernalia(doc, data, data.stages.length+2, pageLogoString)

        buildAppendixA(doc, data)
        addPageParaphernalia(doc, data, data.stages.length+3, pageLogoString)

        buildAppendixB(doc, data)
        addPageParaphernalia(doc, data, data.stages.length+4, pageLogoString)

        buildAppendixC(doc, data)
        addPageParaphernalia(doc, data, data.stages.length+5, pageLogoString)

    
        doc.save(name);
        if (cb) cb()
        // })
    })
}

