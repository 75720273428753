<template>
    <div :class="{
        [$style.textNode]: true,
        }">
        <div :class="$style.dropdownWrap">
        <select v-on:change="changeSelection($event, 'assesmentType')" :class="$style.dropdown">
                <option
                    v-for="(option, i) in node.children[0].options"
                    :key="i"
                    :value="option.value"
                    :selected="node.children[0].selectedValue == option.value"
                >
                    {{option.text}}
                </option>
        </select>
            <FontAwesomeIcon :icon="chevronIcon" :class="$style.chevron" />
        </div>
        <p>
            {{node.children[1]}}
        </p>
        <div :class="$style.dropdownWrap">
            <select v-on:change="changeSelection($event, 'count')" :class="$style.dropdown">
                    <option
                        v-for="(option, i) in node.children[2].options"
                        :key="i"
                        :value="option.value"
                        :selected="node.children[2].selectedValue == option.value"
                    >
                        {{option.text}}
                    </option>
            </select>
                <FontAwesomeIcon :icon="chevronIcon" :class="$style.chevron" />
        </div>
        <span v-if="node.allowRemoval" @click="removeNode($event)" :class="$style.removeButton">
            <FontAwesomeIcon
                :class="$style.faIcon"
                :icon="closeIcon"
            />
        </span>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'

export default {
    name: 'TelescopicTextNode',
    props: {
        node: [Object, String],
        opened: {
            type: Boolean,
            default: false
        },
        paragraphs: {
            type: Boolean,
            default: false
        },
        index: Number,
        showIndex: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            showOptions: false,
            selectId: this.node.id,
            selectValue: undefined,
            mouseover: false,
        }
    },
    computed: {
        closeIcon() {
            return faTimesCircle
        },
        chevronIcon() {
            return faChevronDown
        }
    },
    methods: {
        selectedOption(node){
            if (node.selectedIndex) return node.options[node.selectedIndex]
            return node.default
        },
        changeSelection(event, step){
            this.$emit('node-selection-changed', this.node.id, event.target.value, step)
        },
        removeNode(event){
            this.$emit('node-removed', this.node.id, event.target.value)
        },
    }
}
</script>

<style src="./TelescopicTextNode.css" module>
