<template>
    <div :class="$external.outer">
        <div :class="$external.top">
            <Header />
            <div :class="$external.app">
                <div :class="$external.introSection">
                    <h2 :class="$external.title">
                        Test the quality of your hiring process
                    </h2>
                    <p :class="$external.introText">
                        Play with different approaches using our hiring
                        calculator to see which process gets you the best or
                        worst candidates and learn how to improve.
                    </p>
                </div>
                <div
                    :class="[$external.inputCard, $external.inputCardOverrides]"
                    id="buildHiringPipe"
                >
                    <h2 :class="$external.header1">
                        What does your hiring process look like?
                    </h2>
                    <div :class="$external.applyCountRow">
                        <p :class="$external.body">Typically</p>
                        <Select
                            style="width: 200px;"
                            v-model="startingPool"
                            :options="[
                                {
                                    text: 10,
                                    value: 10,
                                },
                                {
                                    text: 50,
                                    value: 50,
                                },
                                {
                                    text: 100,
                                    value: 100,
                                },
                                {
                                    text: 200,
                                    value: 200,
                                },
                                {
                                    text: 1000,
                                    value: 1000,
                                },
                                {
                                    text: 2000,
                                    value: 2000,
                                },
                            ]"
                        />
                        <p :class="$external.body">candidates apply</p>
                    </div>
                    <TelescopicText
                        :tree="nodeTree"
                        v-on:node-selection-changed="catchSelectionChange"
                        v-on:node-removed="catchRemoval"
                    />
                    <p :class="$external.body">
                        Then we hire
                        {{ numberOfCandidatesToHire == 1 ? "that" : "those" }}
                        {{ numberOfCandidatesToHire }} candidate{{
                            numberOfCandidatesToHire == 1 ? "" : "s"
                        }}
                    </p>
                </div>
                <div
                    :class="{
                        [$external.buttonRow]: true,
                        [$external.bottomRow]: !resultsVisible,
                        [$external.noBoxShadow]: resultsVisible,
                    }"
                    style="z-index:7;"
                >
                    <span
                        style="display: flex; width: 100%; justify-content:space-between;"
                        ><Button
                            @clicked="
                                addNode('unstructuredInterviews', 5, true)
                            "
                            color="secondary"
                            :class="$marmotOverrides.secondaryButtonOverride"
                        >
                            <FontAwesomeIcon
                                slot="iconLeft"
                                :icon="plusIcon"
                                :class="$style.fa"
                            />
                            <span slot="buttonText">Add a stage</span>
                        </Button>
                        <Button
                            @clicked="refreshPipeline"
                            color="primary"
                            :disabled="this.disableRunButton"
                            :class="$marmotOverrides.primaryButtonOverride"
                        >
                            <span slot="buttonText">Calculate results</span>
                        </Button>
                    </span>
                </div>
                <div
                    v-show="resultsVisible"
                    :class="[$external.buttonRow, $external.bottomRow]"
                >
                    <HiringSimulation
                        :pipeline="pipeline"
                        :startingPool="startingPool"
                        ref="simulator"
                        @finished-calculating="enableButton()"
                        :resultsVisible="resultsVisible"
                        @setResultsVisible="setResultsVisible"
                    />
                </div>
                <HubSpotForm
                    v-show="resultsVisible"
                    :portalId="6528443"
                    :formId="'3294b1ee-f13d-4368-8882-63f12967d4a1'"
                    @submitted="formSubmitted"
                >
                    <template v-slot:header>Get the full report</template>
                    <p>
                        The report includes the simulation data, explains the
                        science behind the numbers, and suggests ways to evolve
                        your hiring.
                    </p>
                    <template v-slot:submitted>
                        <p>
                            Thanks for completing the Hiring Calculator... the
                            full report should start downloading in a moment,
                            but if not you can
                            <a @click="download()"
                                >try downloading it again here</a
                            >.
                        </p>
                    </template>
                </HubSpotForm>
                <ImproveYourHiring />
                <ShareSection />
            </div>
            <Footer />
        </div>
        <div :class="$external.below">
            <span
                ><div :class="$external.topRect"></div>
                <div :class="$external.topShape"></div
            ></span>
            <div :class="$external.bottomShape"></div>
        </div>
    </div>
</template>

<script>
import { Button, Select } from "@applied/marmot"
import Footer from "./components/Footer/Footer.vue"
import Header from "./components/Header/Header.vue"
import ImproveYourHiring from "./components/ImproveYourHiring/ImproveYourHiring.vue"
import HiringSimulation from "./components/HiringSimulation/HiringSimulation.vue"
import TelescopicText from "./components/AssesmentList/AssesmentList.vue"
import ShareSection from "./components/ShareSection/ShareSection.vue"
import HubSpotForm from "./components/HiringSimulation/HubSpotForm.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faRunning, faPlus } from "@fortawesome/free-solid-svg-icons"
import tree from "./utils/tree.js"
import assessment from "./utils/assessment.js"
import baseX from "./lib/base-x.js"
import { v4 as uuidv4 } from "./lib/uuid"

var base62 = baseX(
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
)

export default {
    name: "App",
    components: {
        Header,
        ImproveYourHiring,
        TelescopicText,
        HiringSimulation,
        FontAwesomeIcon,
        ShareSection,
        Select,
        Button,
        Footer,
        HubSpotForm,
    },
    mounted() {
        const params = this.$route.query

        if (params && params.state) {
            try {
                const state = JSON.parse(base62.decode(params.state))
                if (state.run) this.simulationFirstRun = true
                this.startingPool = state.pool ? parseInt(state.pool) : 200
                state.pipeline.forEach((step, i) => {
                    const allowRemoval = i > 0
                    this.addNode(step.type, step.limit, allowRemoval)
                })
            } catch (err) {
                console.error(err)
            }
        }

        if (!this.pipeline || this.pipeline.length === 0) {
            this.addNode("biographical", 100, false)
        }
    },
    data() {
        return {
            resultsVisible: false,
            stateString: null,
            simulationFirstRun: false,
            disableRunButton: false,
            startingPool: 200,
            pipelineVersion: 0,
            initialText: `
                    Our hiring process consists of
                    [automated sifting]
                    followed by
                    [1,2,3,4] 
                    [
                        tea
                        |
                        steaming hot tea
                    ].
                    There's more
                `,
            nodeTree: {
                children: [],
            },
        }
    },
    watch: {
        startingPool() {
            this.saveState()
            if (this.simulationFirstRun) this.refreshPipeline()
        },
    },
    computed: {
        runIcon() {
            return faRunning
        },
        plusIcon() {
            return faPlus
        },
        parsedTree() {
            return tree.parse(this.initialText)
        },
        pipeline() {
            this.pipelineVersion
            return this.nodeTree.children
                .filter((node) => node.children)
                .map((node) => {
                    const assessmentTypeNode = tree.findNodeByProp(
                        "name",
                        "assessmentType",
                        node.children
                    )
                    const limitNode = tree.findNodeByProp(
                        "name",
                        "limit",
                        node.children
                    )

                    if (assessmentTypeNode && limitNode) {
                        return {
                            type: assessmentTypeNode.selectedValue,
                            limit: limitNode.selectedValue,
                        }
                    }
                })
        },
        numberOfCandidatesToHire() {
            this.pipelineVersion
            if (!this.pipeline || !this.pipeline.length) return 0
            return this.pipeline[this.pipeline.length - 1].limit
        },
    },
    methods: {
        formSubmitted() {
            this.$refs.simulator.download()
        },
        setResultsVisible(value) {
            this.resultsVisible = value
        },
        enableButton() {
            this.disableRunButton = false
        },
        catchRemoval(id) {
            tree.deleteNodeById(this.nodeTree, id)
            this.saveState()
            if (this.simulationFirstRun) this.refreshPipeline()
        },
        catchSelectionChange(id, value, step) {
            console.log("HELLO")
            console.log(id)
            console.log(tree.findNodeById(this.nodeTree, id))
            const arrayStep = step === "assesmentType" ? 0 : 2
            tree.findNodeById(this.nodeTree, id).children[
                arrayStep
            ].selectedValue = value
            this.saveState()
            if (this.simulationFirstRun) this.refreshPipeline()
        },
        refreshPipeline() {
            if (this.disableRunButton) return
            this.pipelineVersion++
            this.disableRunButton = true
            this.$nextTick(() => {
                this.$refs.simulator.refresh()
            })
            this.simulationFirstRun = true
        },
        saveState() {
            const pipelineString = base62.encode(
                new Buffer(
                    JSON.stringify({
                        pipeline: this.pipeline,
                        run: this.simulationFirstRun,
                        pool: this.startingPool,
                    })
                )
            )
            if (this.$route.query.state !== pipelineString) {
                this.stateString = pipelineString
                this.$router.push({
                    query: {
                        state: pipelineString,
                    },
                })
            }
        },
        addNode(assessmentType, limit, allowRemoval) {
            this.nodeTree.children.push({
                id: uuidv4(),
                opened: true,
                allowRemoval,
                children: [
                    // 'We use',
                    {
                        id: uuidv4(),
                        name: "assessmentType",
                        selectedValue: assessmentType,
                        options: Object.keys(assessment.types)
                            .filter(
                                (typeName) => assessment.types[typeName].display
                            )
                            .map((typeName) => {
                                return {
                                    text: assessment.types[typeName].name
                                        .toLowerCase()
                                        .replace("applied", "Applied"),
                                    value: typeName,
                                }
                            })
                            .sort((a, b) => {
                                return a.text > b.text
                            }),
                    },
                    "to choose",
                    {
                        id: uuidv4(),
                        name: "limit",
                        selectedValue: limit,
                        options: [
                            1,
                            2,
                            3,
                            4,
                            5,
                            10,
                            20,
                            30,
                            50,
                            100,
                            150,
                            200,
                        ].map((number) => {
                            return {
                                text: `${number} candidate${
                                    number === 1 ? "" : "s"
                                }`,
                                value: number,
                            }
                        }),
                    },
                ],
            })
            this.saveState()
            if (this.simulationFirstRun) this.refreshPipeline()
        },
    },
}
</script>

<style module="$external" src="./App.css" />
<style module="$marmotOverrides" src="./marmotOverrides.css" />

<style lang="scss">
html {
    scroll-behavior: smooth;
}
body {
    font-family: Poppins, Helvetica, Arial, sans-serif;
}

pre {
    font-size: 12px;
    text-align: left;
    background: rgba(0, 0, 0, 0.5);
    color: #9f9;
    padding: 20px;
}
</style>
<style lang="scss" module>
.disabled {
    cursor: not-allowed;
    background-color: #aab1bf;
    border-color: #aab1bf;
    color: #222a39;
}

.intro {
    flex: 1;
    max-width: 1000px;
    min-height: 2000px;
    margin: 0 auto;
    padding: 60px 40px 0 60px;
    position: relative;
}
.intro .inner {
    max-width: 520px;
}
.intro p,
.intro li {
    font-size: 18px;
}
.introImage {
    flex: 1;
}

// .header { background-color:#131b29}
.header {
}

.header img {
    max-width: 240px;
    margin: 20px 40px 0;
}

.container {
    margin: 0 auto;
    display: flex;
}

.backgroundBar {
    background-color: #eee;
    margin: 0 0 100px;
}

.inputArea {
    flex: 1;
    background-color: #083572;
}

.container .input {
    // flex: 1;
    // flex: 1;
    margin: 50px auto;
    max-width: 400px;
    // position: relative;
    // top: -320px;
    background: white;
    border-radius: 20px;
    padding: 20px 20px 40px 60px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

canvas {
    background: white;
}

.alternatives {
    margin: 50px auto;
    max-width: 480px;
    color: white;
}
.alternatives li {
    margin: 10px 0;
}
.alternatives a {
    color: white;
}
</style>
