<template>
    <span :class="{
        [$style.outer]:true,
        [$style.danger]:(this.color === 'danger'),
        [$style.warn]:(this.color === 'warn'),
        [$style.default]:(this.color === 'default'),
        }">{{formattedValue}}{{suffix}}</span>
</template>

<script>
export default {
    name: 'NumberIndicator',
    props: {
        suffix: String,
        value: Number,
        decimalPlaces: Number,
        rules: {
            type: Array,
            default: undefined,
        },
        statusDanger: Object,
        statusWarn: Object,
        statusValue: Number,
    },
    data() {
        return {
            
        }
    },
    computed: {
        formattedValue(){
            if ( !isNaN(this.decimalPlaces) ) return this.value.toFixed(this.decimalPlaces)
            return this.value
        },
        color() {
            const value = isNaN(this.statusValue) ? this.value : this.statusValue
            if (this.statusDanger) {
                if (this.statusDanger.lt && value < this.statusDanger.lt) return 'danger'
                if (this.statusDanger.gt && value > this.statusDanger.gt) return 'danger'
            }
            if (this.statusWarn) {
                if (this.statusWarn.lt && value < this.statusWarn.lt) return 'warn'
                if (this.statusWarn.gt && value > this.statusWarn.gt) return 'warn'
            }
            return 'default'
        },
    },
}
</script>

<style module src="./NumberIndicator.css" />
