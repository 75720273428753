<script>
import Chart from 'chart.js'
import { Scatter } from 'vue-chartjs'

export default {
    extends: Scatter,
    // mixins: [reactiveProp],
    props:{
        data: Object,
        options: Object,
    },
    mounted () {
        this.renderChart(this.data, this.options)
    },
    registerPlugin: (plugin) => {
        Chart.pluginService.register(plugin);
    }
}


</script>
