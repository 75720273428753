
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'App',
        // component: Login,
        // beforeEnter: (to, from, next) => {
        //     auth.isUserLoggedIn()
        //         .then(state => {
        //             if (state) {
        //                 next('/dashboard')
        //             } else {
        //                 next(
        //                     `/login?path=${encodeURIComponent(
        //                         getRedirectDestination(to),
        //                     )}`,
        //                 )
        //             }
        //         })
        //         .catch(() => {
        //             next(
        //                 `/login?path=${encodeURIComponent(
        //                     getRedirectDestination(to),
        //                 )}`,
        //             )
        //         })
        // },
    },
    // {
    //     path: '/text-analysis-signup',
    //     name: 'text-analysis-signup',
    //     component: () => import('@/pages/NewTATSignup/NewTATSignup'),
    //     meta: {
    //         title: 'Applied - Welcome to Applied',
    //     },
    // },
    // {
    //     path: '/org-signup/template-list',
    //     name: 'template-list',
    //     component: () => import('@/pages/TemplateList/TemplateList'),
    //     meta: {
    //         title: 'Applied - Welcome to Applied',
    //     },
    // },
    // {
    //     path: '/org-signup/view-template/:templateId',
    //     name: 'view-template',
    //     component: () => import('@/pages/TemplateView/TemplateView'),
    //     meta: {
    //         title: 'Applied - Welcome to Applied',
    //     },
    // },
    // {
    //     path: '/org-signup-new/:stepNumber',
    //     name: 'org-signup-new',
    //     component: () => import('@/pages/OrgSignup/OrgSignup'),
    //     meta: {
    //         title: 'Applied - Welcome to Applied',
    //     },
    // },
    // {
    //     path: '/education/:stepName?',
    //     name: 'education',
    //     component: () => import('@/pages/Education/Education'),
    //     meta: {
    //         title: 'Applied - Welcome to Applied',
    //     },
    // },
    // {
    //     path: '/welcome',
    //     name: 'welcome-flow',
    //     component: () => import('@/pages/OnboardingWizard/OnboardingWizard'),
    //     meta: {
    //         title: 'Applied - Welcome to Applied',
    //     },
    // },
    // {
    //     path: '/login',
    //     component: () => import('@/pages/Login/Login'),
    //     beforeEnter: (to, from, next) => {
    //         store.commit('storeRedirect', to.query.path)
    //         auth.isUserLoggedIn()
    //             .then(state => {
    //                 if (state) {
    //                     next('/dashboard')
    //                 } else {
    //                     next()
    //                 }
    //             })
    //             .catch(() => {
    //                 next()
    //             })
    //     },
    // },
    // {
    //     path: '/:token/reset-password/',
    //     name: 'Password Reset',
    //     meta: {
    //         title: 'Applied - reset your password',
    //     },
    //     component: () => import('@/pages/PasswordReset'),
    // },
    // {
    //     path: '/:token/create-password/',
    //     name: 'Password Creation',
    //     meta: {
    //         title: 'Applied - create your password',
    //     },
    //     component: () => import('@/pages/PasswordReset'),
    // },
    // {
    //     path: '/settings',
    //     name: 'Organisation Settings',
    //     component: () => import('@/pages/Account'),
    //     meta: {
    //         title: 'Applied  Organisation Settings',
    //     },
    //     beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    // },
    // {
    //     path: '/email-templates',
    //     name: 'Email Templates',
    //     component: () =>
    //         import('@/pages/EmailTemplateManagement/EmailTemplateManagement'),
    //     meta: {
    //         title: 'Applied - Organisation Email Templates',
    //     },
    //     beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    // },
    // {
    //     path: '/profile',
    //     name: 'Your Profile',
    //     component: () => import('@/pages/Settings'),
    //     meta: {
    //         title: 'Applied  Your Profile',
    //     },
    //     beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    // },
    // {
    //     path: '/dashboard',
    //     name: 'dashboard',
    //     component: Dashboard,
    //     meta: {
    //         title: 'Applied  Your Dashboard',
    //     },
    //     beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    // },
    // {
    //     path: '/search',
    //     name: 'Search results',
    //     component: () => import('@/pages/SearchResults'),
    //     meta: {
    //         title: 'Applied  Your Dashboard',
    //     },
    //     beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    // },
    // {
    //     path: '*',
    //     component: Login,
    //     beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    // },
]

const router = new Router({
    routes,
})


export default router
