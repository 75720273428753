<template>
    <div :class="$style.wrapper">
        <div :class="$style.header">
            <img alt="Applied logo" src="@/assets/applied-logo-grey.png" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'Header'
}
</script>

<style module src="./Header.css" />
