<template>
    <div style="width: 80%; margin: 0 auto;" id="hubSpotForm">
        <h3><slot name="header"></slot></h3>
        <slot v-if="!submitted"></slot>
        <slot v-else name="submitted"></slot>
        <div id="hubspotForm" v-once></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            submitted: false,
        }
    },
    props: {
        portalId: {
            type: [String, Number],
            required: true,
        },
        formId: {
            type: String,
            required: true,
        },
    },
    mounted() {
        const self = this

        // Workaround to make `onFormSubmit` work as per
        // https://community.hubspot.com/t5/APIs-Integrations/Form-callback-throws-unrelated-jquery-error/m-p/232121
        window.jQuery = window.jQuery || (() => ({
            // these are all methods required by HubSpot
            change: () => {},
            trigger: () => {},
        }));

        const script = document.createElement("script");
        script.src="https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: self.portalId,
                    formId: self.formId,
                    target: "#hubspotForm",
                    region: "na1",
                    onFormSubmitted: self.onFormSubmitted,
                    translations: {
                        en: {
                            // required: "Hey! That's required!",
                            // invalidDate: "Please enter a real date",
                            submitText: "Get the full report",
                            // fieldLabels: {
                                // email: "Electronic mail"
                            // }
                        }
                    },
                    inlineMessage: '&nbsp;',
                    // submitButtonClass: 'HubSpotFormSubmitButton',
                })
            }
        })
    },
    methods: {
        onFormSubmitted(form){
            this.submitted = true
            this.$emit('submitted', form)
        }
    }
}
</script>
<style>
.content-download form.hs-form.stacked input {
    background-color: red;
}
</style>