<template>
    <div :class="$style.wrapper">
        <h3 :class="$style.title">Improve your hiring</h3>
        <div :class="$style.row">
            <div :class="$style.section" style="margin-right: 20px;">
                <img
                    src="../../assets/applied-icons-blue_mission.png"
                    :class="$style.defaultImage"
                    width="150"
                />
                <p :class="$style.bodyText">
                    Take the guesswork out of your company’s growth with
                    Applied.
                </p>
                <Button
                    color="primary"
                    :class="$marmotOverrides.primaryButtonOverride"
                    @clicked="openUrlInNewTab('https://www.beapplied.com/request-a-demo')"
                >
                    <span slot="buttonText">Book a demo</span>
                </Button>
            </div>
            <div :class="$style.section" style="margin: 0 20px;">
                <img
                    src="../../assets/applied-icons-blue_education.png"
                    :class="$style.defaultImage"
                    width="150"
                />
                <p :class="$style.bodyText">
                    Learn how to make your hiring predictive for long-term
                    growth.
                </p>
                <Button
                    color="primary"
                    :class="$marmotOverrides.primaryButtonOverride"
                    @clicked="openUrlInNewTab('https://www.beapplied.com/training-how-to-debias-your-recruitment')"
                >
                    <span slot="buttonText">Book a training session</span>
                </Button>
            </div>
            <div :class="$style.section" style="margin-left: 20px;">
                <img
                    src="../../assets/applied-icons-yellow_goals.png"
                    :class="$style.defaultImage"
                    width="150"
                />
                <p :class="$style.bodyText">
                    Check out the latest advice on how to debiase your hiring
                    process.
                </p>
                <Button
                    color="primary"
                    :class="$marmotOverrides.primaryButtonOverride"
                    @clicked="openUrlInNewTab('https://www.beapplied.com/blog')"
                >
                    <span slot="buttonText">Read more</span>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { Button } from "@applied/marmot"

export default {
    name: "ImproveYourHiring",
    components: {
        Button,
    },
    methods: {
        openUrlInNewTab(url) {
            window.open(url, '_blank').focus()
        }
    }
}
</script>

<style module="$marmotOverrides" src="../../marmotOverrides.css" />
<style module src="./ImproveYourHiring.css" />
