<template>
    <div :class="$style.outer">
        <div :class="$style.wrapper">
            <span>
                <p :class="$style.resultsHeader">
                    How step <span :class="$style.stepBubble">1</span> of your
                    hiring process performed...
                </p>
                <img
                    src="../../../assets/dummyChart.png"
                    :class="$style.defaultImage"
                />
            </span>
            <span>
                <p :class="$style.resultsHeader">
                    How step <span :class="$style.stepBubble">1</span> of your
                    hiring process performed...
                </p>
                <img
                    src="../../../assets/dummyChart.png"
                    :class="$style.defaultImage"
                />
            </span>
        </div>
        <div :class="$style.blurit">
            <FontAwesomeIcon :class="$style.faIcon" :icon="lockIcon" />
            <p :class="$style.bluritBody">
                Want to see how the rest of your hiring steps performed?
            </p>
            <a href="#hubSpotForm" style="text-decoration:none;"
                ><Button
                    color="primary"
                    :class="$marmotOverrides.primaryButtonOverride"
                >
                    <span slot="buttonText">Download the full report</span>
                </Button></a
            >
        </div>
    </div>
</template>

<script>
import { Button } from "@applied/marmot"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faLockAlt } from "@fortawesome/pro-light-svg-icons"

export default {
    name: "BlurCTASection",
    components: {
        FontAwesomeIcon,
        Button,
    },
    computed: {
        lockIcon() {
            return faLockAlt
        },
    },
}
</script>

<style module="$marmotOverrides" src="../../../marmotOverrides.css" />
<style module src="./BlurCTASection.css" />
