<template>
    <div v-if="resultsVisible" :class="$style.resultsModal">
        <div v-if="this.calculating">
            <half-circle-spinner
                :size="120"
                color="#eee"
                style="margin: 20px auto 20px"
            />
            <p style="text-align:center; max-width:400px; margin:20px auto">
                Running the simulation {{ simulationCount }} times using the
                results of scientific studies
            </p>
        </div>
        <div v-else-if="results" :class="$style.results">
            <SummaryStats
                :efficiencySummary="efficiencySummary"
                :diversitySummary="diversitySummary"
                :predictivitySummary="predictivitySummary"
            />
            <h3 :class="$style.resultsHeader">
                Based on the data you gave us, on average:
            </h3>

            <div :class="$style.key">
                <ul style="list-style-type:none; padding: 0">
                    <li :class="$style.infoRow">
                        In this process
                        <NumberIndicator
                            :statusWarn="{ lt: 0.6 }"
                            :statusDanger="{ lt: 0.4 }"
                            :statusValue="goodHires / hired"
                            :value="100 - Math.round((goodHires / hired) * 100)"
                            :decimalPlaces="0"
                            suffix="%"
                        />
                        of your hires will not be among the best 10% of your
                        applicants
                    </li>
                    <li :class="$style.infoRow">
                        Out of the 10 best candidates you didn't hire
                        <NumberIndicator
                            :statusWarn="{ gt: 0.6 }"
                            :statusDanger="{ gt: 0.8 }"
                            :statusValue="(hired - perfectHires) / hired"
                            :value="((hired - perfectHires) / hired) * 10"
                            :decimalPlaces="0"
                        />
                        of them
                    </li>
                    <li :class="$style.infoRow">
                        <NumberIndicator
                            :statusWarn="{ lt: 20 }"
                            :statusDanger="{ lt: 50 }"
                            :statusValue="lowestAveragePercentile"
                            :value="100 - lowestAveragePercentile"
                            :decimalPlaces="0"
                            suffix="%"
                        />
                        of candidates you didn't hire were better than the
                        weakest person you hired
                    </li>
                    <li :class="$style.infoRow">
                        You spent an estimated
                        <NumberIndicator
                            :statusWarn="{ gt: 20 }"
                            :statusDanger="{ gt: 30 }"
                            :value="totalTime"
                            :decimalPlaces="0"
                        />
                        working days per candidate hired
                    </li>
                </ul>
            </div>

            <!-- <h3>How your '{{stepName(0)}}' stage performed</h3> -->
            <!-- <div><a
                    v-for="(stage,i) in this.stages" 
                    :key="i" 
                    @click="selectedStage = i"
                    :class="{
                        [$style.chartLink]: true,
                        [$style.chartLinkActive]: selectedStage === i,
                    }"
                >stage {{i+1}}</a></div> -->
            <h3 :class="$style.resultsHeader">
                How step <span :class="$style.stepBubble">1</span> of your
                hiring process performed...
            </h3>
            <p :class="$style.bodyBold">
                You used {{ pipelineStep1.name }} to choose
                {{ pipelineStep1.count }} candidates
            </p>
            <p :class="$style.body">
                How many great candidates did you lose at the first hurdle? How
                many mismatched candidates did you decide to waste time on?
            </p>
            <div :class="$style.row">
                <div v-for="(stage, i) in this.stages" :key="i">
                    <div
                        :class="{
                            [$style.stageChart]: true,
                            [$style.selected]: selectedStage === i,
                        }"
                    >
                        <HireScatter
                            :data="datasetForStage(stage, i)"
                            :options="options"
                        />
                    </div>
                </div>
                <div :class="$style.legend">
                    <div :class="$style.legendRow">
                        <div :class="[$style.legendSquare, $style.green]"></div>
                        <p :class="[$style.body, $style.noPad]">
                            Identified talent
                        </p>
                    </div>
                    <div :class="$style.legendRow">
                        <div
                            :class="[$style.legendSquare, $style.yellow]"
                        ></div>

                        <p :class="[$style.body, $style.noPad]">
                            Missed talent
                        </p>
                    </div>
                    <div :class="$style.legendRow">
                        <div :class="[$style.legendSquare, $style.red]"></div>

                        <p :class="[$style.body, $style.noPad]">
                            Potential mis-hires
                        </p>
                    </div>
                    <div :class="$style.legendRow">
                        <div :class="[$style.legendSquare, $style.grey]"></div>

                        <p :class="[$style.body, $style.noPad]">
                            Correctly rejected
                        </p>
                    </div>
                </div>
            </div>
            <BlurCTASection />
            <TipCard v-if="nudges.length" :class="$style.tipCardSpacer">
                <span slot="message">
                    <ul style="list-style-type:none; padding: 0">
                        <li v-for="(nudge, i) in this.nudges" :key="i">
                            {{ nudge }}
                        </li>
                    </ul>
                    <div
                        style="display:flex; justify-content: space-between; align-items: center"
                    >
                        <span>
                            <p :class="$style.titleSection">
                                Try out a different approach
                            </p>
                            <p :class="$style.zeroMarginBody">
                                Enter some other options into the calculator and
                                rerun to see the results.
                            </p>
                        </span>
                        <div>
                            <a
                                href="#buildHiringPipe"
                                style="text-decoration:none;"
                                ><Button
                                    color="secondary"
                                    :class="
                                        $marmotOverrides.secondaryButtonOverride
                                    "
                                >
                                    <FontAwesomeIcon
                                        slot="iconLeft"
                                        :icon="undoIcon"
                                        :class="$style.fa"
                                    />
                                    <span slot="buttonText">Add a stage</span>
                                </Button></a
                            >
                        </div>
                    </div>
                </span>
            </TipCard>
            <!-- <div :class="$style.registerPanel">
                <HubSpotForm
                    :portalId="6528443"
                    :formId="'3294b1ee-f13d-4368-8882-63f12967d4a1'"
                    @submitted="formSubmitted()"
                >
                    <template v-slot:header>Get the full report</template>
                    <p>
                        The report includes the simulation data, explains the
                        science behind the numbers, and suggests ways to evolve
                        your hiring.
                    </p>
                    <template v-slot:submitted>
                        <p>
                            Thanks for completing the Hiring Calculator... the
                            full report should start downloading in a moment,
                            but if not you can
                            <a @click="download()"
                                >try downloading it again here</a
                            >.
                        </p>
                    </template>
                </HubSpotForm>
                <!-- <button @click="download()" :class="{[$style.disabled]:this.downloadButtonDisabled}">
                    <FontAwesomeIcon :icon="downloadIcon" :class="$style.fa" />
                    Get full report
                </button> -->
            <!-- </div> -->

            <!-- <div>
                <h3>Other ways to improve your hiring</h3>
                <ol>
                    <li>Check out our free webinars or training days</li>
                    <li>Join the Applied community</li>
                    <li>Check out the Applid platform itself</li>
                </ol>
            </div> -->

            <!-- <pre>{{JSON.stringify(this.results, null, '  ')}}</pre> -->
        </div>
    </div>
</template>

<script>
import { Button } from "@applied/marmot"
import BlurCTASection from "./BlurCTASection/BlurCTASection.vue"
import assessment from "@/utils/assessment"
import { generate } from "@/utils/report"
import SummaryStats from "./SummaryStats/SummaryStats"
import HireScatter from "./HireScatter"
import NumberIndicator from "./NumberIndicator/NumberIndicator.vue"
import TipCard from "../TipCard/TipCard.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faDownload, faUndo } from "@fortawesome/free-solid-svg-icons"
import { faTimes } from "@fortawesome/fontawesome-free-solid"
import { HalfCircleSpinner } from "epic-spinners"

const borderColourCorrect = "#799935" //rgb(74, 155, 0)' 97C13E
const borderColourMissed = "#EAAD2E" //rgb(255, 155, 0)'
const borderColourRejected = "rgba(160, 160, 160, 1)"
const borderColourIncorrect = "#C74036" //rgb(155, 0, 0)'

HireScatter.registerPlugin({
    id: "quadrantLabels",
    afterDatasetsDraw: (chart) => {
        chart.ctx.textAlign = "center"
        chart.ctx.font = "14px Poppins"

        chart.ctx.fillStyle = borderColourMissed
        chart.ctx.textAlign = "left"

        chart.ctx.fillStyle = borderColourIncorrect
        chart.ctx.textAlign = "right"

        chart.ctx.fillStyle = borderColourCorrect
        chart.ctx.textAlign = "right"

        chart.ctx.fillStyle = borderColourRejected
        chart.ctx.textAlign = "left"
    },
})

export default {
    name: "HiringSimulation",
    components: {
        HireScatter,
        FontAwesomeIcon,
        HalfCircleSpinner,
        BlurCTASection,
        NumberIndicator,
        TipCard,
        Button,
        SummaryStats,
    },
    data() {
        return {
            closed: true,
            company: {
                email: "",
                name: "",
                country: "",
                employees: "",
                sector: "",
            },
            downloadButtonDisabled: false,
            minimumRuntime: 1000,
            simulationCount: 100,
            calculating: false,
            results: null,
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                labelString: "Actual ability 👉",
                                display: true,
                                fontSize: 20,
                            },
                            id: "y-axis",
                            type: "linear",
                            ticks: {
                                min: -100,
                                max: 100,
                                stepSize: 4,
                                display: true,
                                maxTicksLimit: 20,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            grid: {
                                drawOnChartArea: true,
                            },
                            scaleLabel: {
                                labelString:
                                    "How good they seemed at the time 👉",
                                display: true,
                                fontSize: 20,
                            },
                            id: "x-axis",
                            type: "linear",
                            drawTicks: false,
                            ticks: {
                                min: -100,
                                max: 100,
                                stepSize: 4,
                                display: true,
                                maxTicksLimit: 20,
                            },
                            // offset:true,
                            gridLines: {
                                // display: false,
                                drawTicks: true,
                            },
                        },
                    ],
                },
            },
            selectedStage: 0,
            datasetVersion: 0,
        }
    },
    props: {
        pipeline: Array,
        startingPool: Number,
        resultsVisible: Boolean,
    },
    computed: {
        efficiencySummary() {
            return this.totalTime > 7 ? "bad" : "good"
        },
        diversitySummary() {
            let badCount = 0
            const hiredQuality = this.goodHires / this.hired
            if (hiredQuality < 0.5) {
                badCount++
            }
            const overlookedRate = (this.hired - this.perfectHires) / this.hired
            if (overlookedRate > 0.75) {
                badCount++
            }
            if (this.lowestAveragePercentile > 0.75) {
                badCount++
            }

            return badCount > 1 ? "bad" : "good"
        },
        pipelineStep1() {
            return {
                name: assessment.types[this.pipeline[0].type].name,
                count: this.pipeline[0].limit,
            }
        },
        undoIcon() {
            return faUndo
        },
        nudges() {
            this.datasetVersion

            const nudges = this.pipeline
                .map((stage) => assessment.types[stage.type].nudge)
                .filter((nudge) => nudge)

            return [...new Set(nudges)]
        },
        stages() {
            this.datasetVersion
            return assessment.byStage(this.results)
        },
        downloadIcon() {
            return faDownload
        },
        closeIcon() {
            return faTimes
        },
        totalTime() {
            this.datasetVersion
            return assessment.getTotalTimeSpent(this.results)
        },
        perfectHires() {
            this.datasetVersion
            return assessment.getTopCandidatesHired(this.results)
        },
        goodHires() {
            this.datasetVersion
            const targetPercentile = 90
            return assessment.getAverageTopPercentileCandidates(
                this.results,
                targetPercentile
            )
        },
        hired() {
            this.datasetVersion
            const stages = this.pipeline
            return parseInt(stages[stages.length - 1].limit)
        },
        mistakePercentiles() {
            this.datasetVersion
            return "blah"
        },
        lowestAveragePercentile() {
            this.datasetVersion
            if (!this.results) return ""
            return assessment.getAverageLowestPercentile(this.results)
        },
        lowestPercentile() {
            this.datasetVersion
            if (!this.results) return ""
            return assessment.getLowestPercentile(this.results)
        },
    },
    methods: {
        setResultsVisible(value) {
            this.$emit("setResultsVisible", value)
        },
        formSubmitted() {
            this.download()
        },
        stepName(i) {
            return assessment.types[this.pipeline[i].type].name
        },
        datasetForStage(stage, i) {
            this.datasetVersion
            const filteredCandidateIds = new Set()
            const targetRank = stage.remainingCandidates.length - 1

            return {
                datasets: [
                    {
                        label: "Correctly identified talent",
                        xAxisID: "x-axis",
                        yAxisID: "y-axis",
                        borderColor: borderColourCorrect,
                        backgroundColor: "#C7E28D", //rgb(174, 255, 0)',
                        data: stage.remainingCandidates
                            .filter((candidate) => candidate.rank <= targetRank)
                            .map((candidate) => {
                                filteredCandidateIds.add(candidate.id)
                                return {
                                    x: candidate.assessments[i] * 25,
                                    y: candidate.ability * 25,
                                }
                            }),
                    },
                    {
                        label: "Weak candidates brought through",
                        xAxisID: "x-axis",
                        yAxisID: "y-axis",
                        borderColor: borderColourIncorrect,
                        backgroundColor: "rgb(255, 100, 100)",
                        data: stage.remainingCandidates
                            .filter((candidate) => {
                                return !filteredCandidateIds.has(candidate.id)
                            })
                            .map((candidate) => {
                                filteredCandidateIds.add(candidate.id)
                                return {
                                    x: candidate.assessments[i] * 25,
                                    y: candidate.ability * 25,
                                }
                            }),
                    },
                    {
                        label: "Great candidates you missed",
                        xAxisID: "x-axis",
                        yAxisID: "y-axis",
                        borderColor: borderColourMissed,
                        backgroundColor: "rgb(255, 255, 0)",
                        data: stage.candidates
                            .filter((candidate) => {
                                if (filteredCandidateIds.has(candidate.id))
                                    return false
                                if (candidate.rank > targetRank) return false
                                return true
                            })
                            .map((candidate) => {
                                filteredCandidateIds.add(candidate.id)
                                return {
                                    x: candidate.assessments[i] * 25,
                                    y: candidate.ability * 25,
                                }
                            }),
                    },
                    {
                        label: "Everyone else",
                        xAxisID: "x-axis",
                        yAxisID: "y-axis",
                        borderColor: borderColourRejected,
                        backgroundColor: "rgba(47, 152, 208, 0)",
                        data: stage.candidates
                            .filter((candidate) => {
                                return !filteredCandidateIds.has(candidate.id)
                            })
                            .map((candidate) => {
                                return {
                                    x: candidate.assessments[i] * 25,
                                    y: candidate.ability * 25,
                                }
                            }),
                    },
                ],
            }
        },
        numberSuffix(number) {
            const lastDigit = number % 10
            if (lastDigit === 1) return "st"
            if (lastDigit === 2) return "nd"
            if (lastDigit === 3) return "rd"
            else return "th"
        },
        refresh() {
            this.setResultsVisible(true)
            this.calculating = true
            const start = new Date().getTime()
            let self = this

            setTimeout(() => {
                self.results = assessment.simulateMultiplePipelines(
                    self.pipeline,
                    self.startingPool,
                    self.simulationCount
                )

                const end = new Date().getTime()
                setTimeout(() => {
                    self.calculating = false
                    self.datasetVersion++
                    self.$emit("finished-calculating")
                }, Math.max(self.minimumRuntime + start - end, 10))
            })
        },
        download() {
            this.downloadButtonDisabled = true
            generate(
                {
                    logo: {
                        url: require("@/assets/applied-logo-grey.png"),
                        width: 48,
                        height: 21.75,
                    },
                    pipeline: this.pipeline,
                    stages: this.stages, //,assessment.byStage(this.results),
                    company: this.company,
                    assessmentTypes: assessment.types,
                    results: this.results,
                },
                document.querySelectorAll("canvas"),
                "applied-pipeline-calculator.pdf",
                () => {
                    this.downloadButtonDisabled = false
                }
            )
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module src="./HiringSimulation.css" />
<style module="$marmotOverrides" src="../../marmotOverrides.css" />
