<template>
    <div :class="$style.card">
        <FontAwesomeIcon :icon="lightbulb" :class="$style.lightbulb" />
        <div :class="$style.cardContent">
            <slot name="message">Here's a tip</slot>
        </div>
    </div>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons'

export default {
    name: 'TipCard',
    components: {
        FontAwesomeIcon,
    },
    computed: {
        lightbulb() {
            return faLightbulbOn
        },
    },
}
</script>

<style module src="./TipCard.css" />
