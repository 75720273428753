const {Ziggurat} = require('../lib/normal')
const zig = new Ziggurat()

const types = {
    workSamples : {
        name: 'Work samples',
        signal: 0.64,//3.2,        // from the spreadsheet
        noise: 1,//5,           // from the spreadsheet
        correlation: 0.54,  // from Schmidt/Hunter, for reference
        minutesPerCandidate: 3, // guesstimate
        description: `Work samples are anything from sample code, to responses to scenario-based questions. They're one of the best ways to assess candidates.`,
        recommendations: `Opinions on written answers vary, even when you have a fixed marking scheme. To solve this, get multiple independent judgements on each one. To be more objective, it also helps to look at a batches of work samples (e.g. all answers to question 1) in isolation from the rest of each application.`,
        display: false,
        source: `Schmidt/Hunter (1998)`,
        nudge: `Try using Applied's approach to work samples to increase predictive power`,
    },
    workSamplesApplied : {
        name: 'Work samples',
        signal: 0.9,//4.5,
        noise: 1,//5,
        correlation: 0.7,
        minutesPerCandidate: 3,
        description: `Applied's work samples improve upon regular work samples in several ways. Firstly the review process counteracts nearly a dozen categories of bias. Secondly the analytics allow you to continuously improve your hiring.`,
        recommendations: `We're not aware of a better way to assess candidates at the written stage. From here you just need to iterate and evolve your questions.`,
        display: true,
        source: `Applied's own research`,
    },
    generalMentalAbility : {
        name: 'General mental ability tests',
        signal: 0.594,//2.97,
        noise: 1,//5,
        correlation: 0.51,
        minutesPerCandidate: 1,
        description: `“General Mental Ability tests are standardised assessments that measure learning capacity, observation, and problem solving. Testing candidates this way can be fairly effective, but can be expensive and can also skew towards white & asian men, so be careful.`,
        recommendations: `Most teams that use these tests are dealing with high volumes and there aren't many good alternatives. Ask your vendor about bias, and whether they have to normalise the results of their tests on a curve. Also, high pressure timed tests can have an adverse impact on women & other groups that don't relate to actual ability, so be generous with any time limits.`,
        display: true,
        source: `Schmidt/Hunter (1998)`,
    },
    structuredInterviews : {
        name: 'Interview (structured)',
        signal: 0.594,//2.97,
        noise: 1,//5,
        correlation: 0.51,
        minutesPerCandidate: 150,
        description: `Structured interviews keep interview questions consistent across candidates, and provide a marking guide to reduce bias.`,
        recommendations: `If you're interviewing this way you're already ahead of most. Make sure you have diverse interview panels and focus on situational/simulation questions rather than "tell me about a time when...". The next level up from here is to understand which questions are predictive of actual ability, and to do that you need to look at Applied's structured interview analysis.`,
        display: true,
        source: `Schmidt/Hunter (1998)`,
    },
    appliedInterviews : {
        name: 'Applied structured interviews',
        signal: 0.9,//2.97,
        noise: 1,//5,
        correlation: 0.7,
        minutesPerCandidate: 150,
        description: `Applied's interviews build on structured interviews in two ways. Firstly, detailed scoring data helps you select candidates with more precision and less bias. Secondly, analysis of how each question performs allows you to constantly improve the performance of your interviews.`,
        recommendations: `If you're interviewing this way you're already ahead of the game. We recommend job simulation style interviews.`,
        display: true,
        source: `Applied's own research`,
    },
    peerRatings : {
        name: 'Peer ratings',
        signal: 0.574,//2.87,
        noise: 1,//5,
        correlation: 0.49,
        minutesPerCandidate: 30,
        display: false,
        source: `Schmidt/Hunter (1998)`,
    },
    jobKnowledge : {
        name: 'Job knowledge tests',
        signal: 0.56,//2.8,
        noise: 1,//5,
        correlation: 0.48,
        minutesPerCandidate: 1,
        display: false,
        source: `Schmidt/Hunter (1998)`,
    },
    jobTryout : {
        name: 'Job tryout',
        signal: 0.494,//2.47,
        noise: 1,//5,
        correlation: 0.44,
        minutesPerCandidate: 150, // two interviewers for an hour, plus admin time
        display: false,
        source: `Schmidt/Hunter (1998)`,
    },
    integrity : {
        name: 'Integrity tests',
        signal: 0.44,//2.2,
        noise: 1,//5,
        correlation: 0.41,
        minutesPerCandidate: 20,
        display: false,
        source: `Schmidt/Hunter (1998)`,
    },
    phoneScreen : {
        name: 'Phone screen',
        signal: 0.42,//2.05,
        noise: 1,//5,
        correlation: 0.38,
        minutesPerCandidate: 150, // two interviewers for an hour, plus admin time
        display: true,
        source: `Schmidt/Hunter (1998)`,
    },
    unstructuredInterviews : {
        name: 'Interview (traditional)',
        signal: 0.42,//2.05,
        noise: 1,//5,
        correlation: 0.38,
        minutesPerCandidate: 150, // two interviewers for an hour, plus admin time
        description: `Traditional interviews set a candidate against one or more interviewers, but with no fixed questions. They're inconsistent and prone to unintended bias`,
        recommendations: `Choose the questions up front and write down what you're looking for. Adding that structure to interviews reduces unintended bias and keeps assessment consistent across candidates.`,
        display: true,
        source: `Schmidt/Hunter (1998)`,
        nudge: `Structured interviews typically predict performance better and leave less room for unintended bias`,
    },
    assessmentCentres : {
        name: 'Assessment centers',
        signal: 0.41,//2,
        noise: 1,//5,
        correlation: 0.37,
        minutesPerCandidate: 60,
        description: `Assessment centers are a mid-strength assessment used for high volume hiring. They're typically structured, which is good, but offer less predictivity than work samples and can favor extroverts.`,
        recommendations: `For high volume hiring it may be hard to replace assessment centres, but if you bring down candidate volume using a highly predictive sift-stage like work samples you may be able to reduce or retire your assessment centers.`,
        display: false,
        source: `Schmidt/Hunter (1998)`,
        // nudge: `A work sample`,
    },
    conscientiousness: {
        name: 'Conscientiousness tests',
        signal:  0.32,//1.6,
        noise: 1,//5,
        correlation: 0.22, //SH 2016
        minutesPerCandidate: 20,
        display: false,
        source: `Schmidt/Hunter (2016)`,
    },
    biographical: {
        name: 'CV/resumé sifting',
        signal: 0.3,//1.5,
        noise: 1,//5,
        correlation: 0.3,
        minutesPerCandidate: 3,
        description: `Résumé are easily available, expected, and quick to read, although the information contained in them is often highly filtered and inconsistent across candidates.`,
        recommendations: `Résumés have been shown to be a weak predictor of later performance. Obviously there are exceptions but you need to take care. We recommend using work samples instead as your main sift-stage assessment, and refer back to the résumé if needed.`,
        display: true,
        source: `Schmidt/Hunter (1998)`,
        nudge: `Try using work samples rather than résumés to improve the perfomance of your sift stage`,
    },
    referenceChecks: {
        name: 'Reference checks',
        signal: 0.264,//1.32,
        noise: 1,//5,
        correlation: 0.26,
        minutesPerCandidate: 30,
        description: `Speaking to candidates former colleagues is a useful way to verify what they wrote on their résumé and fish for information.`,
        recommendations: `Reference checks can be good, but are highly dependent on how forthright the referee is so quality varies a lot. As such it makes sense to put this last, and purely to look for red flags`,
        display: true,
        source: `Schmidt/Hunter (1998)`,
    },
    emotionalIntelligence: {
        name: 'Emotional intelligence tests',
        display: true,
        signal: 0.25,
        noise: 1,
        correlation: 0.24,
        minutesPerCandidate: 10,
        description: `Emotional Intelligence or EQ tests assess candidates self-awareness, self-management, social awareness and relationship management. Emotional intelligence (or EQ) is a very powerful skill that's useful in most professions. Like most assessments that are abstracted from the actual job it's power is limited.`,
        recommendations: `Some of EQ tests mix personality-based measures and ability based measures. You should focus on the ability based measures, since the personality measures have a zero order validity (Schmidt and Hunter, 2016). You can also try to assess emotional intelligence through work sample questions. Given the modest predictive power of EQ testing, it works best when used to round out the picture provided by other assessment methods.`,
        source: `Schmidt/Hunter (2016)`,
    },
    personality: {
        name: 'Personality tests',
        display: true,
        signal: 0.22,
        noise: 1,
        correlation: 0.22,
        minutesPerCandidate: 10,
        description: `Most personality tests follow the 'Big 5' model. There's evidence that the 'conscientiousness' measure is moderately predictive of performance, but the other 4 metrics much less so.`,
        recommendations: `The conscientiousness trait has an effect on job performance, and the extraversion trait has low capacity to predict job performance (Schmidt and Hunter, 2016). Given the modest predictive power of personality testing, it works best when used to round out the picture provided by other assessment methods. You also need to ensure that you're not reducing diversity so make sure you focus on personality traits that matter.`,
        source: `Schmidt/Hunter (2016)`,
        nudge: `Conscientiousness tests and EQ tests seem to perform better than personality tests`,
    },
    experienceYears: {
        name: 'Years of experience',
        display: false,
        signal: 0.184,//0.92,
        noise: 1,//5,
        correlation: 0.18,
        minutesPerCandidate: 2,
        source: `Schmidt/Hunter (1998)`,
    },
    educationYears: {
        name: 'Years of education',
        display: false,
        signal: 0.1,//0.5,
        noise: 1,//5,
        correlation: 0.1,
        minutesPerCandidate: 2,
        source: `Schmidt/Hunter (1998)`,
    },
    interests: {
        name: 'Hobbies & interests',
        display: false,
        signal: 0.1,//0.5,
        noise: 1,//5,
        correlation: 0.1,
        minutesPerCandidate: 2,
        source: `Schmidt/Hunter (1998)`,
    },
    graphology: {
        name: 'Graphology (handwriting)',
        signal: 0.02,//0.1,
        noise: 1,//5,
        correlation: 0.02,
        minutesPerCandidate: 20,
        description: `Proponents of handwriting analysis claim that you can make deductions about a candidate from their handwriting.`,
        recommendations: `Proponents of handwriting analysis are wrong.`,
        display: false,
        source: `Schmidt/Hunter (1998)`,
        nudge: `Nobody really uses handwriting analysis anymore do they?`,
    },
    magic: {
        name: 'Magic sorting hat',
        display: false,
        signal: 1,
        noise: 0,
        correlation: 1,
        minutesPerCandidate: 1,
        source: `Hogwarts school`,
    },
}
exports.types = types


exports.randomCandidates = (number, finalCandidateCount) => {
    const candidates = []
    for (let i = 0; i < number; i++ ){
        candidates.push({
            id: i,
            ability: zig.nextGaussian(),
            assessments: [],
        })
    }
    candidates.sort(sortByProperty('ability'))
    candidates.forEach((candidate, i) => {
        candidate.percentile = Math.floor(100*(number-i)/number)
        candidate.rank = i
        candidate.top = i+1 <= finalCandidateCount
    })
    return candidates
}

const sortByProperty = (property) => {
    return (a,b) => { return b[property] - a[property] }
}

const simulateAssessment = (candidate, assessmentType) => {
    const perceivedSignal = candidate.ability * assessmentType.signal
    const allowedNoise = zig.nextGaussian() * assessmentType.noise
    return perceivedSignal + allowedNoise
}

exports.simulatePipeline = (pipeline, numberOfCandidates) => {
    if ( pipeline.length === 0 ) return
    const finalCandidateCount = pipeline[pipeline.length-1].limit
    const candidates = exports.randomCandidates(numberOfCandidates, finalCandidateCount)
    const trueAbilityThreshold = candidates[finalCandidateCount-1].ability
    
    const results = {
        stages:[],
        abilityThreshold: trueAbilityThreshold,
    }
    
    let remainingCandidates

    // Run through each assessment stage in the pipeline
    pipeline.forEach((stage, i) => {
        const assessmentType = types[stage.type]
        let stageCandidates = remainingCandidates || candidates

        results.stages[i] = {
            type: stage.type,
            name: assessmentType.name,
            limit: stage.limit,
            candidates: stageCandidates,
            candidateCount: stageCandidates.length,
            minutesSpent: stageCandidates.length * assessmentType.minutesPerCandidate,
        }

        stageCandidates.forEach((candidate,j) => {
            candidate.lastAssessment = simulateAssessment(candidate, assessmentType)
            candidate.assessments[i] = candidate.lastAssessment
        })
        stageCandidates.sort(sortByProperty('lastAssessment'))

        remainingCandidates = stageCandidates.slice(0, stage.limit)

        results.stages[i].remainingCandidates = remainingCandidates
        results.stages[i].topCandidatesRemaining = remainingCandidates
            .filter(candidate => candidate.ability >= results.abilityThreshold )
            .length
    })

    results.hired = remainingCandidates

    return results
}

exports.simulateMultiplePipelines = (pipeline, numberOfCandidates, numberOfPipelines) => {
    const results = {pipelines:[]}
    for (let i = 0; i < numberOfPipelines; i++){
        results.pipelines.push(exports.simulatePipeline(pipeline, numberOfCandidates))
    }
    return results
}


const average = (array) => {
    if (!array.length) return undefined
    const clean = array.filter(a => !isNaN(a))
    return clean.reduce((a, b) => a + b) / clean.length;
}

exports.getLowestPercentile = (results) => {
    let lowestPercentile = 100
    results.pipelines.forEach((pipelineResults) => {
        pipelineResults.hired
            .forEach((candidate) => {
                if (candidate.percentile < lowestPercentile) lowestPercentile = candidate.percentile
            })
    })
    return lowestPercentile
}

exports.getAverageLowestPercentile = (results) => {
    const lowestPercentiles = []
    results.pipelines.forEach((pipelineResults, i) => {

        const filtered = pipelineResults.hired
            .sort((a,b) => a.percentile - b.percentile)
        
        if (filtered.length === 0) return
        lowestPercentiles.push(filtered[0].percentile)
    })
    if (lowestPercentiles.length === 0) return 0
    return average(lowestPercentiles)
}

exports.getAnalyticsForStages = (results) => {

}

exports.getTotalTimeSpent = (results) => {
    let stages = results.pipelines[0].stages // will be constant across them all
    let total = 0
    stages.forEach(stage => {
        total += stage.minutesSpent
    })
    return Math.round(total/(60*8))
}



exports.getAverageTopPercentileCandidates = (results, targetPercentile) => {
    
    const topCounts = []
    results.pipelines.forEach((simulation) => {
        topCounts.push(simulation.hired.filter(candidate => candidate.percentile >= targetPercentile).length)
    })
    return average(topCounts)
}




exports.getTopCandidatesHired = (results) => {
    
    const topCounts = []
    results.pipelines.forEach((simulation) => {
        topCounts.push(simulation.hired.filter(candidate => candidate.top).length)
    })
    return average(topCounts)
}


exports.byStage = (results) => {

    // console.log(`splitting by stage multiple with ${numberOfCandidates}`)
    const stages = []
    results.pipelines.forEach((simulation, i) => {
        simulation.stages.forEach((stage,j) => {
            if (!stages[j]) stages[j] = {pipelines:[], candidates:[], remainingCandidates:[], truePositives:[], falsePositives:[], trueNegatives:[], falseNegatives:[]}
            stages[j].pipelines[i] = stage
            stages[j].type = stage.type
            const truePositives = stage.remainingCandidates.filter(candidate => candidate.top).length
            const falsePositives = stage.remainingCandidates.filter(candidate => !candidate.top).length
            stages[j].truePositives.push(truePositives)
            stages[j].falsePositives.push(falsePositives)
            stages[j].trueNegatives.push(stage.candidates.filter(candidate => !candidate.top).length - falsePositives)
            stages[j].falseNegatives.push(stage.candidates.filter(candidate => candidate.top).length - truePositives)
            if ( i === 0 ) {
                stages[j].candidates.push(...stage.candidates)
                stages[j].remainingCandidates.push(...stage.remainingCandidates)
            }
        })
    })
    stages.forEach(stage => {
        stage.avgTruePositives = average(stage.truePositives)
        stage.avgFalsePositives = average(stage.falsePositives)
        stage.avgTrueNegatives = average(stage.trueNegatives)
        stage.avgFalseNegatives = average(stage.falseNegatives)
    })
    return stages
}


// results.stages[i] = {
//     type: stage.type,
//     name: assessmentType.name,
//     limit: stage.limit,
//     candidates: stageCandidates,
//     candidateCount: stageCandidates.length,
//     minutesSpent: stageCandidates.length * assessmentType.minutesPerCandidate,
// }

// stageCandidates.forEach((candidate,j) => {
//     candidate.lastAssessment = simulateAssessment(candidate, assessmentType)
//     candidate.assessments[i] = candidate.lastAssessment
// })
// stageCandidates.sort(sortByProperty('lastAssessment'))

// remainingCandidates = stageCandidates.slice(0, stage.limit)

// results.stages[i].remainingCandidates = remainingCandidates
// results.stages[i].topCandidatesRemaining = remainingCandidates
//     .filter(candidate => candidate.ability >= results.abilityThreshold )
//     .length